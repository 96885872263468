.login {
  background-color: rgb(0 0 0 / 0.6);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.login-card {
  min-height: 600px;
}

.modal-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.modal-custom-body > div {
  padding: 60px 60px !important;
}

.modal-main {
  // max-width: 450px;
  // // width: 378px;
  // margin: auto;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 768px) {
  .login-card {
    min-height: 500px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login {
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login {
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login {
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .login {
  }
}
