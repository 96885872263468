.auth-form {
  max-width: 800px;
  width: 100%;
  // padding: 30px 15px;
  text-align: center;
  margin: 0 auto;
}
.auth-bottom {
  display: flex;
  justify-content: flex-end;
}
