// Colors
$color-primary: #000000;
$color-secondary: #00b050;
$color-alert: #ff0000;
$color-default: #141414;
$color-gray: #646464;
$color-white: #ffffff;
$color-brown: #ed7d31;
$color-blue: #0070c0;
$btn-blue: #00a0d2;
$btn-grey: #646464;
$btn-pink: #ca48df;
$btn-green: #00a510;
$btn-red: #ff4949;
$color-green: #00b050;
$color-navy: #002060;
$color-yellow: #ffc000;
$color-purple: #7030a0;
$color-bg-gray: #f0f0f0;
$color-bg-blue: #f5fafe;
$color-black: #000000;
$color-border: rgba(0, 0, 0, 0.125);

// Margin Bottom
$margin-s: 5px;
$margin-m: 15px;

// Input
$padding-input: 0.3rem 0.5rem;
$radius-input: 6px;
$border-input: 1px solid black;

// Font Family  Montserrat-SemiBold and Noto Sans JP
$font-family: "Montserrat-SemiBold", "Noto Sans JP", sans-serif;
