@use "../../../styles/variables" as v;

.icon-button {
  width: 276px;
  height: 32px;
  border-radius: 6px;
  padding: 24px 12px;
  background-color: v.$btn-grey;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.icon-button {
  &-GREY {
    background-color: #646464;
    width: 360px;
  }
  &-BLUE {
    background-color: #00a0d2;
  }
}
/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 768px) {
  .icon-button {
    height: 32px;
    padding: 24px 4px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
