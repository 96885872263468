.header {
  // "h-12 bg-black text-white lg:bg-white lg:text-black px-5 lg:px-16 flex justify-between items-center"
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background: white;
  color: #000;
  padding-left: 4rem;
  padding-right: 4rem;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 600px) {
  .header {
    background: black;
    color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header {
    background: black;
    color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .header {
    background: black;
    color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .header {
    background: white;
    color: #000;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header {
    background: white;
    color: #000;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
