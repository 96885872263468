

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    flex-direction: column;
}

.delet-Account-btn{
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
    width: 200px;
    margin-bottom: 10px;
    
}