.text {
  font-weight: 400;
  font-family: "NotoSans-Regular";
}
// .bold {
//   font-weight: bold;
// }

.text {
  &-black {
    color: black;
  }
  &-yellow {
    color: yellow;
  }
  &-white {
    color: white;
  }
  &-pink {
    color: pink;
  }
  &-xxl {
    font-size: 64px;
  }
  &-xl {
    font-size: 48px;
  }
  &-36 {
    font-size: 36px;
  }
  &-l {
    font-size: 24px;
  }
  &-m {
    font-size: 20px;
  }
  &-s {
    font-size: 16px;
  }
  &-xs {
    font-size: 14px;
  }
  &-xxs {
    font-size: 12px;
  }
  &-10 {
    font-size: 10px;
  }
  &-true {
    font-weight: 700;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-700 {
    font-weight: 700;
  }
}

// .white {
//   color: white;
// }
// .pink {
//   color: pink;
// }
